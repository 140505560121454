<template>
            <Card class="card-custom">
              <template #title>
                        
                <Toolbar>
                  <template #start>
                    <back-button />
                          <h3 class="ml-2">
                            {{ $t('add_head') }}
                          </h3>
                  </template>
                  <template #end>
                    <Button @click="saveScript" :disabled="!isValidScript" :loading="loadingFlag" :label="$t('save')"
                          icon="pi pi-save" class="p-button-sm p-button-success" />
                  </template>
              </Toolbar>
                <Card class="mt-2">
                  <template #content>
                    <div class="content-card">

                            <!-- <InputText type="text" :placeholder="$t('title')" v-model="newScript.name" class="p-inputtext-sm"/> -->
                            <div class="radiobuttons">
                                <h3>{{$t('type')}}</h3>
                                <div class="field-radiobutton _no-icon">
                                    <RadioButton id="type" name="script" value="script" v-model="newScript.type" />
                                    <label for="script">
                                        Script
                                    </label>
                                </div>
                                <div class="field-radiobutton _no-icon">
                                    <RadioButton id="type" name="link" value="link" v-model="newScript.type" />
                                    <label for="link">
                                        Link
                                    </label>
                                </div>
                                <div class="field-radiobutton _no-icon">
                                    <RadioButton id="type" name="meta" value="meta" v-model="newScript.type" />
                                    <label for="meta">
                                        Meta
                                    </label>
                                </div>
                            </div>
                            <span class="custom-data">
                                <Textarea :disabled="!isTypeSelected" :placeholder="getExample"  v-model="newScript.code" rows="5" />
                                <transition name="scale-transition">
                                <div class="custom-data__is-lock" v-if="!isTypeSelected">
                                    <v-icon>
                                        mdi-lock
                                    </v-icon>
                                    <span>
                                        {{ $t('choose_one_of_option') }}
                                    </span>
                                </div>
                            </transition>
                            </span>
                            <!-- <small class="text-left" v-if="isTypeSelected">
                                {{ $t('for_example') }}: {{ getExample }}
                            </small>
                            <div class="custom-data">
                                
                                <div class="custom-data__inputs">
                                    <div v-for="(item, index) in newScript.code" class="custom-data__templates">

                                        <InputText v-model="item.name" :disabled="!isTypeSelected" type="text" :placeholder="`${$t('attribute')}*`" class="p-inputtext-sm"/>
                                        <InputText v-model="item.content" :disabled="!isTypeSelected" type="text" :placeholder="$t('content')" class="p-inputtext-sm"/>
                                    </div>
                                    
                                    
                               
                                </div>
                                <div class="d-flex justify-start" v-if="isTypeSelected">
                                    <Button @click="increaseCodeArray"
                                    :label="$t('add_more_attribute')"
                                    icon="pi pi-save" class="p-button-sm p-button-success" />
                                </div>
                                
                            </div> -->
                            <div class="radiobuttons">
                                <h3>{{$t('where_on_page')}}</h3>
                                <div class="field-radiobutton">
                                    <RadioButton id="locate" name="head" value="head" v-model="newScript.locate" />
                                    <label for="head">
                                        <v-icon color="var(--text-color)">
                                            mdi-menu-up
                                        </v-icon>
                                        <span>
                                            {{ $t('on_head') }}
                                        </span>
                                    </label>
                                </div>
                                <div class="field-radiobutton">
                                    <RadioButton id="locate" name="body" value="body" v-model="newScript.locate" />
                                    <label for="body">
                                            <v-icon color="var(--text-color)">
                                                mdi-menu-down
                                            </v-icon>
                                            <span>
                                                {{ $t('on_body') }}
                                            </span>
                                        
                                    </label>
                                </div>
                            </div>
                            <v-switch v-model="newScript.active" hide-details :label="$t('active')" color="green accent-4"
            inset class="mt-0 ml-1 custom-switch "></v-switch>
                    </div>
                  </template>
              </Card>
              </template>
              <template #content>
                <div class="d-flex flex-column">
                    
                    
                </div>
            </template>
            </Card>
</template>

<script>
    export default {
        name: 'CreateCustomScript',
        data() {
            return {
                loadingFlag: true,
                newScript:{
                    name: '',
                    code: '',
                //     code: [
                //         {
                //         name: '',
                //         content: '',
                //     }
                // ],
                    locate: '',
                    type: '',
                    active: true,

                },
            }
        },
        watch:{
            //'newScript.type': function() {
            //  this.newScript.code = [{
            //    name: '',
            //    content: ''
            //  }];
            //},
        },
        computed: {
            getExample(){
                const type = this.newScript.type;
                switch(type){
                    case 'link':
                        return '<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png">'
                    case 'script':
                        return '<script src="app.js">'
                    case 'meta':
                        return '<meta name="msapplication-TileColor" content="#8E24AA">'
                        default:
                            return '';
                }
                
            },
            isValidScript(){
                const { type, name, code, locate } = this.newScript;
                // name.trim() !== '' &&
                return type.trim() !== '' && code.trim() !== '' && locate.trim() !== '';
            },
            isTypeSelected(){
                const { type } = this.newScript;
                return type.trim() !== ''
            },
        },
        async mounted(){
            this.loadingFlag = false;
        },
        methods:{
            increaseCodeArray(){
                this.newScript.code.push({
                  name: '',
                  content: ''
                });
            },
            async saveScript(){
                this.loadingFlag = true;
                const create = this.newScript;
                const res = await this.$store.dispatch('customScript/createCustomScript', create)
                if (res) {
                    this.$toast.add({
                      severity: 'success', summary: this.$t('new_script_added'), life: 4000
                    })
                    this.newScript = {
                    name: '',
                    code: [
                        {
                        name: '',
                        content: '',
                    }
                ],
                    locate: '',
                    type: '',

                    };
                    this.$router.push('/custom-scripts/view');
                } else{
                    this.$toast.add({
            severity: 'error', summary: this.$t('just_error'), life: 4000
          })
                }
                this.loadingFlag = false;
            },
        },
        beforeRouteEnter (to, from, next) {
            next(vm =>
            {
                vm.$store.commit('setPageName', 'custom_js')
            }
            )
        }
    }
</script>

<style lang="scss" scoped>
.content-card {
    display: flex;
    flex-direction: column;
    .radiobuttons{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &:not(:last-child) {
            margin-bottom: 6px;
        }
        .field-radiobutton{
            display: flex;
            align-items: center;
            margin-left: 5px;
            &:not(:last-child) {
                margin-bottom: 3px;
            }
            label{
                display: flex;
            align-items: center;
            .v-icon{
                margin: 0 0px 0 5px;
            }
        }
        &._no-icon{
            label{

                margin: 0 0px 0 5px;
            }
        }
        }
    }
        .p-inputtext{
            &:not(:last-child) {
                margin-bottom: 6px;
            }
            
        }
}
.custom-data{
    position: relative;
    //&__templates{
    //    display: flex;
    //    @media (max-width:600px){
    //        .p-inputtext{
    //            flex: 0 1 50%;
    //            &:not(:last-child) {
    //                margin-right: 5px;
    //            }
    //        }
    //    }
    //    @media (min-width:600px){
    //        flex-direction: column;
    //        max-width: 200px;
    //        &:not(:last-child) {
    //            margin-right: 5px;
    //        }
    //    }
    //}
    display: flex;
    textarea{
        display: flex;
        flex: 1 1 100%;
    }
    &__is-lock{
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: var(--mask-blur);
        backdrop-filter: blur(2px);
        border-radius: 6px;
        .v-icon{
            flex: 1 1 100%;
            font-size: 35px;
            color: var(--red-500);
        }
    }
    margin-bottom: 6px;
}
</style>